import React from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { UISref, useCurrentStateAndParams } from '@wix/tpa-router/react';

import {
  selectGroupFetchStatus,
  selectGroupIdBySlug,
  selectIsGroupRejected,
} from 'store/selectors';

import { Container } from 'wui/Container';
import { Spinner } from 'wui/Spinner';
import { EmptyState } from 'wui/EmptyState';
import { Button } from 'wui/Button';

import { GROUP_NOT_FOUND_DATA_HOOK, GROUP_PAGE_ROOT } from './dataHooks';

import { Header } from './Header';
import { ApplicationPage } from './ApplicationPage';

import classes from './styles.scss';

export function GroupPage() {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { params } = useCurrentStateAndParams();

  const status = useSelector(selectGroupFetchStatus(params.slug));
  const groupId = useSelector(selectGroupIdBySlug(params.slug));
  const isRejected = useSelector(selectIsGroupRejected(groupId));

  if (status.loading) {
    return (
      <Container
        fluid={isMobile}
        align="center"
        verticalAlign="middle"
        data-hook={GROUP_PAGE_ROOT}
        className={classes.placeholder}
      >
        <Spinner />
      </Container>
    );
  }

  if (status.error || !params.slug) {
    return (
      <Container
        fluid={isMobile}
        verticalAlign="middle"
        data-hook={GROUP_PAGE_ROOT}
        className={classes.placeholder}
      >
        <EmptyState
          wired
          variant="page"
          data-hook={GROUP_NOT_FOUND_DATA_HOOK}
          title={t('groups-web.groupNotFound.title')}
          subtitle={t('groups-web.groupNotFound.caption')}
          action={
            <UISref state="groups">
              <Button as="a">{t('groups-web.groupNotFound.action')}</Button>
            </UISref>
          }
        />
      </Container>
    );
  }

  if (isRejected) {
    return (
      <Container
        fluid={isMobile}
        verticalAlign="middle"
        data-hook={GROUP_PAGE_ROOT}
        className={classes.placeholder}
      >
        <EmptyState
          variant="page-borderless"
          title={t('groups-web.rejected.title')}
          subtitle={t('groups-web.rejected.caption')}
          action={
            <UISref state="groups">
              <Button as="a">{t('groups-web.rejected.action')}</Button>
            </UISref>
          }
        />
      </Container>
    );
  }

  return (
    <div className={classes.root} data-hook={GROUP_PAGE_ROOT}>
      <Header />
      <ApplicationPage />
    </div>
  );
}
GroupPage.displayName = 'GroupPage';
