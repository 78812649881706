import React, { useMemo } from 'react';
import { type RawParams } from '@wix/tpa-router';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { type IGroup } from 'api/groups/types';
import { GroupAppKey } from 'store/groups/types';

import { type TabItem } from 'wui/Tabs';
import { CounterBadge } from 'wui/CounterBadge';

interface INavigationTab extends TabItem {
  id: GroupAppKey;
  title: string;
  state: string;
  params?: RawParams;
}

export function useApplicationsNavigation(group: IGroup) {
  const { t } = useTranslation();

  return useMemo<Record<GroupAppKey, INavigationTab>>(
    () => ({
      [GroupAppKey.ABOUT_APP]: {
        id: GroupAppKey.ABOUT_APP,
        title: t('groups-web.about'),
        state: 'group.about',
      },
      [GroupAppKey.EVENTS_APP]: {
        id: GroupAppKey.EVENTS_APP,
        title: t('groups-web.events'),
        state: 'group.events',
      },
      [GroupAppKey.FILES_APP]: {
        id: GroupAppKey.FILES_APP,
        title: t('groups-web.files'),
        state: 'group.files',
      },
      [GroupAppKey.FEED_APP]: {
        id: GroupAppKey.FEED_APP,
        title: t('groups-web.discussion'),
        state: 'group.discussion.feed',
      },
      [GroupAppKey.GALLERY_APP]: {
        id: GroupAppKey.GALLERY_APP,
        title: t('groups-web.media'),
        state: 'group.media',
      },
      [GroupAppKey.MEMBERS_APP]: {
        id: GroupAppKey.MEMBERS_APP,
        title: t('groups-web.members'),
        state: 'group.members',
        prefix: group.pendingMembersCount && (
          <CounterBadge
            color="danger"
            value={group.pendingMembersCount as number}
          />
        ),
      },
      [GroupAppKey.CUSTOM_APP]: {
        id: GroupAppKey.CUSTOM_APP,
        title: t('groups-web.custom'),
        state: 'group.custom.tab',
        params: { tabId: 'custom_app' },
      },
      [GroupAppKey.CUSTOM_APP_2]: {
        id: GroupAppKey.CUSTOM_APP_2,
        title: t('groups-web.custom'),
        state: 'group.custom.tab',
        params: { tabId: 'custom_app_2' },
      },
      [GroupAppKey.CUSTOM_APP_3]: {
        id: GroupAppKey.CUSTOM_APP_3,
        title: t('groups-web.custom'),
        state: 'group.custom.tab',
        params: { tabId: 'custom_app_3' },
      },
    }),
    [group.pendingMembersCount],
  );
}
