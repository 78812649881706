import React from 'react';
import {
  RadioButtonGroup as TPARadioButtonGroup,
  RadioButtonGroupProps,
} from 'wix-ui-tpa/cssVars';

export function RadioButtonGroup(props: RadioButtonGroupProps) {
  return <TPARadioButtonGroup {...props} />;
}

RadioButtonGroup.displayName = 'RadioButtonGroup';
