import React from 'react';
import { useSelector } from 'react-redux';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { groupSettingsSave } from '@wix/bi-logger-groups/v2';

import { Role } from '@wix/ambassador-social-groups-v2-group-member/types';

import { Button } from 'wui/Button';
import { Drawer } from 'wui/Drawer';
import { DialogTitle } from 'wui/DialogTitle';
import { RadioButton } from 'wui/RadioButton';
import { DialogActions } from 'wui/DialogActions';
import { DialogContent } from 'wui/DialogContent';
import { DialogContainer } from 'wui/DialogContainer';
import { RadioButtonGroup } from 'wui/RadioButtonGroup';

import { useController } from 'common/context/controller';
import { useDidUpdate } from 'common/hooks';

import { selectGroup, selectIsGroupUpdating } from 'store/selectors';

interface IProps extends React.ComponentProps<typeof Drawer> {
  groupId: string;
}

export function MemberPermissionsDialog(props: IProps) {
  const { groupId, ...rest } = props;

  const bi = useBi();
  const { t } = useTranslation();
  const { group$ } = useController();

  const group = useSelector(selectGroup(groupId));
  const isUpdating = useSelector(selectIsGroupUpdating(groupId));

  const [inviteRole, setInviteRole] = React.useState(
    group.settings?.membersCanInvite ? Role.MEMBER : Role.ADMIN,
  );

  useDidUpdate(() => {
    if (!isUpdating) {
      props.onClose();
    }
  }, [isUpdating]);

  return (
    <Drawer anchor="right" hideCloseButton={false} {...rest}>
      <DialogContainer>
        <DialogTitle title={t('groups-web.group.settings.permissions')} />

        <DialogContent divider>
          <RadioButtonGroup
            name="group-permission"
            label={t('groups-web.group.settings.permissions.caption')}
            disabled={isUpdating}
            onChange={handleChange}
            value={inviteRole}
          >
            <RadioButton
              value={Role.MEMBER}
              label={t('groups-web.group.settings.permissions.all-members')}
            />
            <RadioButton
              value={Role.ADMIN}
              checked
              label={t(
                'groups-web.group.settings.permissions.admin-and-moderators',
              )}
            />
          </RadioButtonGroup>
        </DialogContent>

        <DialogActions>
          <Button variant="basic" outlined fullWidth onClick={props.onClose}>
            {t('groups-web.cancel')}
          </Button>

          <Button
            variant="basic"
            fullWidth
            onClick={handleSave}
            disabled={isUpdating}
            loading={isUpdating}
          >
            {t('groups-web.save')}
          </Button>
        </DialogActions>
      </DialogContainer>
    </Drawer>
  );

  function handleChange(role: Role) {
    setInviteRole(role);
  }

  function handleSave() {
    group$.updateGroupSettings(groupId, {
      membersCanInvite: inviteRole === Role.MEMBER,
    });

    bi.report(
      groupSettingsSave({
        group_id: groupId,
        origin: 'save_button',
        tabName: 'permissions',
        member_toggle: inviteRole === Role.MEMBER,
      }),
    );
  }
}

MemberPermissionsDialog.displayName = 'MemberPermissionsDialog';
