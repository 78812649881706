import {
  createSettingsParam,
  createStylesParam,
  SettingsParamType,
  StyleParamType,
} from '@wix/tpa-settings';

import { transformSettingsKey } from '../helpers';

export const settings = {
  // @deprecated use styleParam instead
  showActivity: createSettingsParam('showActivity', {
    getDefaultValue: () => true,
    type: SettingsParamType.Boolean,
    dangerousKeyTransformationOverride: transformSettingsKey,
  }),
};

export const styles = {
  showActivity: createStylesParam('showActivity', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
};
