import {
  createSettingsParam,
  createStylesParam,
  SettingsParamType,
  StyleParamType,
  wixColorParam,
} from '@wix/tpa-settings';

import { MediaLayout } from '../consts';
import { transformSettingsKey } from '../helpers';

export const settings = {
  // @deprecated use styleParam instead
  showNameOnHover: createSettingsParam('showNameOnHover', {
    getDefaultValue: () => true,
    type: SettingsParamType.Boolean,
    dangerousKeyTransformationOverride: transformSettingsKey,
  }),

  // TODO: facilitate MediaGallery to provide cssVar for this value
  imagesPerRow: createSettingsParam('imagesPerRow', {
    getDefaultValue: () => 5,
    type: SettingsParamType.Number,
  }),
  mediaLayout: createSettingsParam('mediaLayout', {
    type: SettingsParamType.String,
    getDefaultValue: () => MediaLayout.grid,
    dangerousKeyTransformationOverride: transformSettingsKey,
  }),
};

export const styles = {
  overlayColor: createStylesParam('overlayColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.6),
  }),

  showNameOnHover: createStylesParam('showNameOnHover', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
};
