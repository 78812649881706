import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { groupActionClick } from '@wix/bi-logger-groups/v2';
import { useHref } from '@wix/tpa-router/react';

import { selectGroupSlug, selectIsGroupSecret } from 'store/selectors';

import { Share as ShareIcon } from '@wix/wix-ui-icons-common/on-stage';
import { Box } from 'wui/Box';
import { Hide } from 'wui/Hide';
import { IconButton } from 'wui/IconButton';
import { ShareDialog } from 'wui/ShareDialog';
import { ButtonGroup } from 'wui/ButtonGroup';

import { GroupActions } from '../GroupActions';
import { ApplicationBreadcrumbs } from '../ApplicationBreadcrumbs';

import classes from './styles.scss';

interface IMobileControlsProps {
  groupId: string;
}

export function MobileControls(props: IMobileControlsProps) {
  const { groupId } = props;

  const bi = useBi();
  const { t } = useTranslation();

  const slug = useSelector(selectGroupSlug(groupId));
  const isSecretGroup = useSelector(selectIsGroupSecret(groupId));

  const [isOpen, setIsOpen] = useState(false);

  const groupUrl = useHref(
    'group.discussion.feed',
    { slug },
    { absolute: true },
  );

  return (
    <Box align="space-between">
      <ApplicationBreadcrumbs />

      <ButtonGroup>
        <Hide if={isSecretGroup}>
          <IconButton
            aria-label={t('groups-web.a11y.share-group')}
            data-hook="share-button"
            icon={<ShareIcon />}
            className={classes.iconButton}
            onClick={handleOpenShareModal}
          />
        </Hide>
        <GroupActions groupId={groupId} />
      </ButtonGroup>

      <ShareDialog
        isOpen={isOpen}
        data={{ url: groupUrl }}
        onClose={handleCloseShareDialog}
        title={t('groups-web.group.actions.share.group')}
      />
    </Box>
  );

  function handleCloseShareDialog() {
    setIsOpen(false);
  }

  function handleOpenShareModal() {
    setIsOpen(true);

    bi.report(
      groupActionClick({
        origin: 'arrow',
        action: 'share',
        group_id: groupId,
      }),
    );
  }
}

MobileControls.displayName = 'MobileControls';
