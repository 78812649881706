import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';

import { getDisplayForCssVar } from 'common/utils/getDisplayForCssVar';

export const getGroupCssVars: CustomCssVarsFn = ({ styleParams }) => ({
  /**
   * Discussions display styles
   */
  showBreadcrumbs: getDisplayForCssVar(styleParams, 'showBreadcrumbs', 'block'),
  showMembersCount: getDisplayForCssVar(
    styleParams,
    'showMembersCount',
    'block',
  ),

  /**
   * Media display styles
   */
  showNameOnHover: getDisplayForCssVar(styleParams, 'showNameOnHover', 'block'),

  /**
   * Members display styles
   */
  showFollowButton: getDisplayForCssVar(
    styleParams,
    'showFollowButton',
    'block',
  ),

  /**
   * About display styles
   */
  'showActivity--flex': getDisplayForCssVar(
    styleParams,
    'showActivity',
    'flex',
  ),

  /**
   * Text styles
   */
  showMembersBadges: getDisplayForCssVar(
    styleParams,
    'showMembersBadges',
    'block',
  ),
});
